.loginPage {
    width: 100%;
    height: 100%;
    min-height: 100vh;
    background: url("../../../../public/assets/img/bg.jpg");
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
}

.loginModal {
    background-color: #fff;
    border-radius: 6px;
    min-width: 300px;
    max-width: 450px;
    width: 100%;
    margin: 10px;
    padding: 50px 30px;
}

.submitButton {
    width: 100%;
    margin: 10px 0;
}

.loginInfo {
    text-align: center;
}

.logoWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 25px;

    > img {
        height: 55px;
    }
}

.topInfo {
    text-align: center;
    font-size: 20px;
}
