@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap");

html {
  font-family: "Roboto", sans-serif;
  font-size: 0.75rem;
}

// antd overriding styles
.ant-layout {
  background-color: #ffffff !important;
}
.BaseTable__header-cell--sorting {
  display:none !important;
}
.hiddenOverflow {
  overflow:hidden !important;
}

.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
  border: none !important;
}
.hover-blue {
  cursor: pointer;
  &:hover {
    color: blue;
  }
}

.ant-menu.ant-menu-dark,
.ant-menu-dark .ant-menu-sub,
.ant-menu.ant-menu-dark .ant-menu-sub,
.ant-layout-sider-trigger,
.ant-layout-sider {
  background-color: #2d353c !important;
}
.ant-layout-sider-trigger {
  height: 10vh !important;
}


.ant-menu {
  font-size: 12px !important;
}

// main styles

.layout__content {
  height: 100%;
  // width: 100;
  margin: 10px;
}

.page-wrapper {
  padding: 5px 0;
  width: 100%;

  @media (max-width: 1200px) {
    width: 1100px;
  }
}
.checkBoxWrapper {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  label{
    align-items: center !important;
    span {
      top:unset !important;
    }
  }
}
.page-content {
  margin-top:16px;
  padding: 10px 24px;
  width: 100%;
}

.page-title-tooltip-text {
  color: #000c17 !important;
  overflow: auto;
  max-height: 300px;
  max-width: 800px;
  width: 100%;
}

.page-title-tooltip .ant-tooltip-inner {
  padding: 20px !important;
  //border-radius: 5px !important;
  max-height: 500px;
  max-width: 800px;
  width: 100%;
}

.page-title-tooltip {
  max-width: 500px !important;
}

.global-loading-container {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.description-split {
  display: flex;
  flex-direction: row;
  align-items: center;

  > div {
    border-left: 1px solid #f7f7f7;
    margin: 0 20px;
    padding: 20px;
  }
}
.ant-table-content:has(.billing-header-cell) {
  .ant-table-row:nth-child(2n) {
    background:#eeeeee;
    border-width: 1px 0;
    border-color: #d9d9d9;
    color: #4d4c4c;
  }
  .ant-table-cell {
    font-size: 16px;
  }
}
.ant-table-cell:has(.billing-header-cell) {
  border-width: 0 0 1px 0;
  border-color: #000000;
  background:none;
  &:before {
    position: static !important;
  }
}

.billing-header-cell {
  text-align: center;
  font-weight: 700;
}
.inputWithBorderRadius {
  span {
    border-radius: 6px;
  }
  input {
    border-radius: 6px;
  }
}
.addBlockModal:has(.ant-modal-body) {
  .ant-modal-body {
    padding-top:unset;
    padding-right: unset;
    padding-bottom: unset;
  }
}
.table-modal {
  .BaseTable__table.BaseTable__table-main {
    max-width: 100%;
    width:unset !important;
  }
  .universal-table-settings {
    width:unset !important;
  }
  .BaseTable.BaseTable--fixed.BaseTable--expandable.BaseTable--has-frozen-columns.BaseTable--dynamic {
    width:unset !important;
  }
  .BaseTable__body {
    overflow:hidden !important;
  }
}

.dashboards-table {
  width: 100%;
  overflow:hidden;
  .BaseTable {
    // width: 100% !important; commented for fix scroll on the dashboard table
  }
  .BaseTable__table {
    max-width: unset !important;
    height: auto !important;
  }
  .BaseTable__body {
    overflow:hidden !important;
    height:100% !important;
  }
}
.previewTable {
  .BaseTable__body {
    overflow: auto !important;
  }
  .BaseTable {
    overflow:auto !important;
  }
}

.banner {
  position: fixed;
  bottom: 0;
  z-index: 1000;
  cursor: pointer;

  img {
    width: 200px;
    height: auto;
  }

  .closeButton {
    position: absolute;
    top: 5px;
    right: 5px;
    background: white;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    text-align: center;
    padding-bottom: 2px;
    font-weight: 600;
  }
}
.react-resizable-handle.react-resizable-handle-se {
  display:none !important;
}
.react-grid-item > .react-resizable-handle::after {
  content: "";
  display:none !important;
  right: 3px;
  bottom: 3px;
  width: 5px;
  height: 5px;
  border-right: 2px solid rgba(0, 0, 0, 0.4);
  border-bottom: 2px solid rgba(0, 0, 0, 0.4);
}

