
.scriptPane {
    margin: 20px 0;
    width: 500px;
    height: 400px;
    border: 1px solid #2d353c;
    border-radius: 10px;
    font-size: 12px;
}

.copyButton {
    margin: 20px 0;
}
