.schoolSelectorWrapper {
  min-height: 65px;
  border-bottom: 1px solid rgb(39, 49, 53);
  padding-bottom: 10px;
  margin-bottom: 10px;

  > ul {
    padding-top: 12px;
  }
}
.schoolName{
    li{
      height:auto !important;
    }
   div {
    height: auto !important;
  }
}


