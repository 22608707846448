.pace {
  display: none !important;
}

.payment_page__pageWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0;
}

.payment_page__pageLogo > img {
  height: 60px;
  margin-top: 40px;
}

.payment_page__loadingContainer {
  width: 100%;
  height: 70vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.payment_page__loader {
  border: 6px solid #f3f3f3; /* Light grey */
  border-top: 6px solid #545454; /* Blue */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

.payment_page__paymentPageContainer {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  max-width: 320px;
  width: 100%;
}

.payment_page__payerInfo_container {
  margin-bottom: 10px;
}

.payment_page__payerInfo_container > span {
  display: block;
}

.payment_page__paymentInfoContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
  font-size: 14px;
  max-width: 800px;
  width: 100%;
  margin-bottom: 18px;
  margin-top: 20px;
  padding: 16px 10px 8px;
  border-top: 1px solid #bfbfbf;
  border-bottom: 1px solid #bfbfbf;
  row-gap: 10px;
}

.payment_page__paymentInfoContainer > div {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin: 0;
}

@media (max-width: 378px) {
  .payment_page__paymentInfoContainer {
    padding-left: 0;
    padding-right: 0;
  }
}

.payment_page__paymentInfoContainer > div > p {
  color: #4e4e4e;
  margin: 0;
}

.payment_page__paymentInfoContainer > div > div {
  color: #000 !important;
  /*text-align: right;*/
  width: 150px;
  font-size: 16px;
  font-weight: 500;
}

.payment_page__paymentInfoContainer > div > h4 {
  display: block;
  margin-bottom: 20px;
}

.payment_page__paymentAmount {
  margin: 30px 0;
}

.payment_page__cardPaymentContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.payment_page__cardPaymentContainer > div {
  margin-right: 20px;
  padding: 5px 10px;
  border-radius: 5px;
  border: 1px solid #cacaca;
}

.payment_page__payContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.payment_page__payButton {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 315px;
  margin: 10px 0;
  height: 120px !important;
  padding: 0 20px;
  /*border: none;*/
  border-radius: 5px;
  /*font-weight: bold;*/
  text-decoration: none;
  /*color: #fff;*/
  /*background-color: #ff3d4d;*/
  cursor: pointer;
}

.payment_page__payButton:hover {
  /*color: #fff;*/
  text-decoration: none;
}

.payment_page__payButton > div {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: start;
}

.payment_page__payButton svg {
  max-height: 40px;
  width: 50px;
  margin-right: 10px;
}

.payment_page__payButton p {
  margin: 0;
}

.payment_page__secureInfo {
  margin-top: 25px;
  text-align: center;
  color: #4cb062;
  display: inline;
}

.payment_page__secureInfo > span {
  display: inline-block;
  margin-right: 6px;
}

.payment_page__secureInfo > span > svg {
  vertical-align: unset;
}
