.headerContainer {
  min-width: 100%; /* задаем ширину в 100% от ширины экрана */

  margin: 0 auto;
  white-space: nowrap;
  height: 55px;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0;
  background: #fff;
  font-size: 13px;
  box-shadow: 0 -2px 14px #828282;
  z-index: 10;

  > div {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    align-items: center;
  }

  @media (max-width: 1200px) {
    width: max-content;
  }
}

.headerItem {
  margin: 0 15px;
  cursor: pointer;
}

.licenceInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: #262626;

  > p {
    margin: 0;
    line-height: normal;
    font-size: 12px;
  }
}

.userInfo {
  margin: 0 25px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  > span {
    margin-left: 15px;
  }
}

.logoWrapper {
  padding-left: 10px;
  width: 190px;
  height: 55px;
  //border-bottom: 1px solid #f0f2f5;
  margin-bottom: 14px;
  background-color: #fff;
  z-index: 9;

  > img {
    width: 130px;
  }
}
