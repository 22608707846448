.componentContainer {
  overflow: hidden;
  // overflow-y:auto;
  position: relative;
  transition: box-shadow 0.3s;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  &:hover {
    .dropdownContainer {
      display:block !important;
      opacity: 1;
    }
    .dragIcon {
      opacity: 1;
    }
    .resizeHandle {
      display:block !important;
      opacity: 1;
    }
  }
}

.headerBlock {
  position: sticky;
  top: 0;
  z-index: 999;
  padding: 20px 20px 0 20px;
}

.container {
  // overflow: hidden;
  // overflow-y: auto;
  position: relative;
  padding: 0 20px 20px 20px;
  height: calc(100% - 51px);
  display: flex;
  width: 100%;
  overflow: auto;
}

.heading {
  padding:13px;
  overflow:hidden;
  box-shadow:none !important;
  &:hover {
    background:white;
  }
}

.title {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  &:hover {
    cursor: pointer;
  }
  &:hover + .tooltiptext {
    visibility: visible !important;
    z-index: 10000 !important;
    overflow: visible !important;
  }
}

.tooltip {
  position: relative;
  display: inline-block;
}


.tooltiptext {
  visibility: hidden;
  width: max-content;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 10px;
  z-index: 10000;
  overflow: visible;

  display: inline-block;
  position: absolute;
  bottom: -30px;
}

.smoothComponent {
  background:white;
  border-radius:16px;
  // padding:20px;
}

.dropdownContainer {
  margin-top:-20px;
  position: sticky;
  top: 10px;
  right: 0;
  //margin-left:auto; commented for scroll x
  left:98%;
  transform: translateY(-20px);
  cursor: pointer;
  opacity: 0;
  z-index: 9999;
  transition: opacity 0.3s;
  width: 23px;
}
.dragIcon {
  position: sticky;
  top: 0;
  left: 46%;
  transform: translateY(-20px);
  /* background-color: white;
  border: 1px solid #ddd;
  border-radius: 20%; */
  padding: 4px;
  padding-bottom: 0px;
  cursor: move;
  opacity: 0;
  z-index: 9999;
  transition: opacity 0.3s;
  width: 30px;
}

.resizeHandle {
  /* margin-top: 32%; */
  width:23px;
  //margin-left:auto; commented for scroll x
  left:90%;
  position: sticky;
  /* transform:  translateX(99%); */
  /* padding: 4px; */
  bottom: 30px;
  cursor: nwse-resize;
  opacity: 0;
  transition: opacity 0.3s;
  z-index: 9999;

  svg {
    font-size: 18px;
  }
}
@keyframes slideDown {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
}
.fixedHeader {
  z-index:25;
  animation: slideDown 0.7s cubic-bezier(0.32, 0.64, 0.45, 1) forwards;
}
