.BaseTable {
  box-shadow: none;
  min-height: 300px;
  line-height: 1;
}

.BaseTable__table-main {
  outline: none !important;
}

.BaseTable__row-cell {
  > span {
    height: 20px;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.universal-table {
  position: relative;
  box-sizing: border-box;
  margin-top: 20px;
}

.BaseTable__table-main {
  padding-bottom: 0 !important;
  overflow: auto !important;
}

.BaseTable__body {
  overflow: auto !important;
}

.BaseTable__header {
  /*overflow: auto !important;*/
  /*padding-bottom: 5px !important;*/
  /*box-sizing: content-box !important;*/
}

.universal-table-settings {
  background-color: #f3f3f3;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 7px 15px;
}
.table-expanded-indent {
  height: 1px;
  float: left;
}
.table-expanded-loading,
.table-expanded-icon {
  margin-right: 8px;
  font-size: 17px;
  float: left;
  line-height: 0;
  margin-top: 2px;
}

.table-expanded-icon {
  cursor: pointer;
}

.table-expanded-icon.spaced {
  visibility: hidden;
  cursor: default;
}

.table-header-cell {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 7px 0;
  width: 100%;
  justify-content: flex-end;
}

.table-header-cell > div:nth-child(1) {
  height: 54% !important;
}

.BaseTable__table-main .BaseTable__header {
  background-color: transparent;
}
.BaseTable__header-row {
  background-color: #ffffff !important;
}
.BaseTable__row--frozen {
  background-color: #f3f3f3;
}
.table-header-sort {
  display: inline;
  cursor: pointer;
}
.table-header-sort.sort-asc::after,
.table-header-sort.sort-desc::after {
  position: absolute;
  top: 6px;
  right: 10px;
  color: #1890ff;
}
.table-header-sort.sort-asc::after {
  content: "▲";
}
.table-header-sort.sort-desc::after {
  content: "▼";
}
.table-header-cell-question {
  font-size: 12px;
  /*color: #ccc;*/
  margin-right: 3px;
  cursor: pointer;
}
.table-header-cell-question > .anticon {
  display: inline-flex;
}
.table-overlay {
  height: calc(100% + 40px);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.8);

  > div {
    margin-top: -70px;
  }
}
.table-row-empty {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.table-row-empty .ant-empty-normal {
  margin: 0;
}
.table-header-cell-buttons {
  /*justify-content: flex-end;*/
}
.table-header-cell-buttons:hover {
  opacity: 1;
}
