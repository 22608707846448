@import "~antd/dist/antd.css";

.modalHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  span {
    font-weight: 700;
  }
  &:last-child {
    margin-right: 20px;
  }
}
.saveAndClose {
  span {
    font-weight: 400;
    font-size: 14px;
  }

}
.searchInput {
  width: 220px;
}


.utmGrid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;
}
