.information_table {
  :global {
    .ant-table-tbody > tr > td {
      padding: 0 6px !important;
      font-size: 12px;
    }
    .ant-table-thead > tr > th {
      padding: 10px 6px !important;
      font-size: 13px;
    }
  }
}
.selectWrapper{
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  column-gap: 20px;
  margin-bottom: 20px;
}
.addWrapper{
  display: flex;
  column-gap: 20px;
}
