.params {
  display: flex;
  flex-wrap: wrap;
  margin-top: 5px;

  @media (max-width: 1250px) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
}
