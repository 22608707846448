
.switchWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;

    > span {
        margin-left: 10px;
    }
}

.scriptPane {
    margin: 20px 0;
    width: 500px;
    height: 400px;
    border: 1px solid #2d353c;
    border-radius: 10px;
    font-size: 12px;
}

.domainSection {
    margin: 40px 0;
    max-width: 800px;
}

.domainTableTitle {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 30px;
}
