.ad-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  border: 1px solid black;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  font-size: 16px;
  color: #000000;
  width: 300px;
  height: 70px;

  &:hover {
    border-width: 2px;
  }

  &-icon {
    margin-right: 10px;
    font-size: 30px;
  }

  &-text {
    font-weight: bold;
    font-size: 12px;
  }
}
