.filter-wrapper {
  margin-top: 6px;
}

.params__filter {
  display: inline;

  > div {
    display: inline-block;
  }
}

.filter-auto-rk-tab {
  position: absolute;
  top: -53px;
  left: 248px;
}
.filter-manual-ad-tab {
  position: absolute;
  top: -53px;
  left: 238px;
}
.filter-auto-ad-tab {
  display:block;
  margin-top:20px;
}

.params__attr-container {
  margin: 0;
}

.params__attr-list-item {
  display: flex !important;
  flex-direction: row;
  align-items: center;
}

.params__segment-show-button {
  display: flex !important;
  width: inherit;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
}

.params__compare-icon {
  margin-right: 8px;
  margin-bottom: -2px;
  width: 16px;
  height: 16px;
}

.params__attr-select-list {
  width: 260px;
  padding: 0;
  margin-left: -16px;
  margin-right: -16px;
}

.report-list-item,
.segment-list-item {
  padding: 3px 18px !important;
  display: flex;
  align-items: center;
  cursor: pointer;
  min-height: 38px;
  height: 100%;
}

.report-list-item:hover,
.segment-list-item:hover {
  background-color: #f5f5f5;
}

.segment-list-item-controls {
  display: none;
}

.segment-list-item:hover .segment-list-item-controls {
  display: inline-block;
}

.segment-list-item-controls button:hover {
  background-color: transparent !important;
  color: #7d7d7d !important;
}

.report-list-item-controls {
  display: none;
}

.report-list-item:hover .report-list-item-controls {
  display: inline-block;
}

.report-list-item-controls button:hover {
  background-color: transparent !important;
  color: #7d7d7d !important;
}

.filter-popover .ant-popover-inner-content {
  width: 450px;
  padding: 4px 0 0;
  max-height: 330px;
  overflow-y: auto;
}

.filter-popover .ant-tree .ant-tree-node-content-wrapper {
  padding: 2px 4px;
}

.filter-popover .ant-popover-title {
  padding: 7px;
}

.filter-selected-item {
  display: inline-block;
  color: #fefefe;
  padding: 4px 9px;
  cursor: pointer;
  border-radius: 15px;
  margin-bottom: 8px;
}

.segment {
  margin-bottom: 9px;
}

.tree-search-value {
  background-color: #fec047;
  clear: both;
  display: inline-block;
  white-space: nowrap;
}

.tree-search-hide {
  opacity: 0.4;
}

.load-spin {
  display: flex;
  justify-content: center;
  align-content: space-between;
  align-items: center;
  flex-direction: column;
  height: 80%;
}

.request_button {
  margin: 0 5px !important;
  background-color: #ffcc00 !important;
  color: #262626 !important;
  border-radius: 20px !important;
  border: none !important;
  outline: none !important;

  &:hover,
  &:focus {
    background-color: #ffd11b !important;
    color: #262626 !important;
  }
}

.segments-compare-container {
  width: 100%;
  display: flex;
  align-items: start;
  flex-direction: row;
  flex-wrap: nowrap;
  border-top: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
  /*margin-top: 20px;*/
}

.segments-compare-container > div:nth-child(1) {
  border-right: 1px solid #e0e0e0;
}

.segments-compare-block {
  width: 50%;
  min-height: 100px;
  padding: 15px;
}

.segments-compare-block > div:nth-child(1) {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  margin-bottom: 15px;
}

.segments-compare-block > div:nth-child(2) {
  margin-bottom: 15px;
}

.compareIcon {
  margin-right: 8px;
}

.compareIcon > svg {
  width: 16px;
  height: 16px;
  margin-top: -2px;
}

.external-details {
  margin: 10px 0;
}
